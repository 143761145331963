<template>
    <div>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Inter Warehouse ID
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.sto_code }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Shipment Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.shipment_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Source Warehouse
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.src_warehouse.code + ' - ' + this.form.src_warehouse.name }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.delivery_note_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Arrival Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.receive_date) }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.delivery_date) }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    GR Document Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.code }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Attachment
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' }}<a v-if="form.attachment_id > 0" :href="form.attachment.Url">Download Attachment</a>
                    <span v-else>No Attachment</span>
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Note
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.note }}
                </td>
            </tr>
        </table>

        <table width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th colspan="2"></th>
                    <th colspan="2">GR Data</th>
                    <th colspan="2">Conversion</th>
                    <th colspan="3">Batch/Ed</th>
                </tr>
                <tr>
                    <th>
                        <div>SKU Code</div>
                    </th>
                    <th>
                        <div>SKU Description</div>
                    </th>
                    <th>HU</th>
                    <th>Qty</th>
                    <th>HU</th>
                    <th>Qty</th>
                    <th>Batch</th>
                    <th>Expired Date</th>
                    <th>Damaged Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in form.lines_dto" :key="index" style="padding-bottom: 5px">
                    <td :rowspan="item.count"
                        v-if="index == 0 || item.stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                        class="td vs-table--td" style="padding: 5px">
                        {{ item.relocation_item_unit.sku_code }}
                    </td>
                    <td :rowspan="item.count"
                        v-if="index == 0 || item.stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                        class="td vs-table--td" style="padding: 5px">
                        {{ item.relocation_item_unit.item_name }}
                    </td>
                    <td :rowspan="item.count"
                        v-if="index == 0 || item.stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                        class="td vs-table--td" style="padding: 5px">
                        {{ item.relocation_item_unit.name }}
                    </td>
                    <td :rowspan="item.count"
                        v-if="index == 0 || item.stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                        class="td vs-table--td" style="padding: 5px">
                        {{ item.relocation_quantity }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.item_unit.name }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.quantity }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.batch }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.expired_date ? formatDate(item.expired_date) : '' }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ checkOption[item.status_damage].name }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-center w-full pt-6 print-button">
            <vs-button style="margin:'0 auto'" color="primary" @click="handlePrint()">Print</vs-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            checkOption: [{
                id: 0,
                name: "Good"
            }, {
                id: 1,
                name: "Bad"
            },{
                id: 2,
                name: "Expired"
            }],
            id: null,
            attachment: null,
            form: {
                gr_document_number: null,
                note: null,
                shipment_number: null,
                delivery_note_number: null,
                stock_relocation_receive_lines: [],
            },
        }
    },
    methods: {
        async handlePrint() {
            await window.print();
        },

        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.interWarehouse.baseUrlPath}/receive/relocation/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form = resp.data;
                        if (resp.data.delivery_date.match("0001-01-01")) {
                            this.form.delivery_date = moment(resp.data.relocation_date).add(1, "d").toDate();
                        }
                        if (resp.data.receive_date.match("0001-01-01")) {
                            this.form.receive_date = moment().toDate();
                        }
                        if (resp.data.inter_warehouse_id == "") {
                            this.form.inter_warehouse_id = resp.data.relocation_code
                        }
                        if (resp.data.note == "") {
                            this.form.note = resp.data.relocation_note;
                        }
                        this.form.lines_dto.forEach(e => {
                            if (e.item_unit_id == 0) {
                                e.item_unit = e.relocation_item_unit;
                            }
                            if (e.quantity == 0) {
                                e.quantity = e.relocation_quantity;
                            }
                            if (e.expired_date.match("0001-01-01")) {
                                e.expired_date = null;
                            }
                            e.check_status = this.checkOption[e.status_damage];

                            e.count = 0;
                            this.form.lines_dto.forEach(x => {
                                if (e.stock_relocation_line_id === x.stock_relocation_line_id) {
                                    e.count++;
                                }
                            });
                        });
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>
<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        background: none !important;
    }

    * {
        background: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print td,
table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
}
</style>
